<template>
    <div>
        <v-row no-gutters>
            <v-col>
                <span class="display-1">Pakketten</span>
                <div class="pb-3" v-html="roadtrip.packageDescription"></div>
            </v-col>
        </v-row>
        <v-divider color="white" class="mb-2"></v-divider>
        <v-row no-gutters>
            <v-col cols="10">
                <div class="">Basispakket</div>
            </v-col>
            <v-col cols="2">
                <div class="text-end">{{getCurrency(roadtrip.mainPrice)}}</div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="10">
                <div class="">Passagier</div>
            </v-col>
            <v-col cols="2">
                <div class="text-end">{{getCurrency(roadtrip.passengerPrice)}}</div>
            </v-col>
        </v-row>
        <div v-if="roadtrip.packedLunchPrice != 0">
            <v-row no-gutters >
                <v-col cols="10">
                    <div class="">Lunchpakket</div>
                </v-col>
                <v-col cols="2">
                    <div class="text-end">{{getCurrency(roadtrip.packedLunchPrice)}}</div>
                </v-col>
            </v-row>
        </div>
        <div v-if="roadtrip.dinnerPrice != 0">
            <v-row no-gutters>
                <v-col cols="10">
                    <div class="">Diner</div>
                </v-col>
                <v-col cols="2">
                    <div class="text-end">{{getCurrency(roadtrip.dinnerPrice)}}</div>
                </v-col>
            </v-row>
        </div>
        <div v-if="roadtrip.hotelPrice != 0">
            <v-row no-gutters>
                <v-col cols="10">
                    <div class="">Hotel</div>
                </v-col>
                <v-col cols="2">
                    <div class="text-end">{{getCurrency(roadtrip.hotelPrice)}}</div>
                </v-col>
            </v-row>
        </div>
        <div v-if="roadtrip.breakfastPrice != 0">
            <v-row no-gutters>
                <v-col cols="10">
                    <div class="">Ontbijt</div>
                </v-col>
                <v-col cols="2">
                    <div class="text-end">{{getCurrency(roadtrip.breakfastPrice)}}</div>
                </v-col>
            </v-row>
        </div>
        <div v-if="roadtrip.otherPrice != 0">
            <v-row no-gutters>
                <v-col cols="10">
                    <div class="">Anders</div>
                </v-col>
                <v-col cols="2">
                    <div class="text-end">{{getCurrency(roadtrip.otherPrice)}}</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import FormatMixin from "@/mixins/format.mixin";
export default {
  name: 'Pakketten',
    props: {
        roadtrip: Object
    },
  components: {
  },
  mixins: [FormatMixin],
  data: () => ({
  }),
  methods: {
  }
}
</script>
<style>

</style>
