<template>
    <v-container class="pt-0 px-0 roadtrip-store-container with-botom-link" :class="{'with-custom-button' : showCustomButton}">
        <v-carousel v-if="dataLoaded"
        :continuous="false"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="350">
            <v-carousel-item
            v-for="(image, i) in roadtrip.productPhotos"
            :key="i">
                <v-sheet
                height="100%"
                tile>
                    <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <ImageDiv :imageSource="image.photoURL" style="object-fit: cover; height: 350px; margin-top: 15px;" />
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
        <LoadingIcon :isLoading="isLoading" />
        <div class="px-2 roadtrip-settings" v-if="!isLoading" >
            <NoRoadtrip v-if="!dataLoaded"/>
            <v-container v-if="dataLoaded">
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex">
                        <span class="display-1">{{roadtrip.tripName}}</span>
                        <span class="body-1 tag-wrap">
                            <v-alert class="tag ml-3 mt-2 pa-0 text-center red" 
                                :class="`${roadtrip.labelColor}`"
                                text--white
                                width="100"
                                dense
                                prominent
                                text>{{roadtrip.label}}</v-alert>
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                    <div class="pb-2" v-html="roadtrip.productText"></div>
                    </v-col>
                </v-row>
                <Pakketten :roadtrip="roadtrip" v-if="roadtrip.roadtripState == RoadtripStateEnum.UPCOMING"/>
                <v-row no-gutters v-if="roadtrip.roadtripState != RoadtripStateEnum.PASSED">
                    <v-col cols="12" class="mt-3 mb-0">
                        <span class="display-1">Planning van {{formatDayMonth(roadtrip.date)}}</span>
                    </v-col>
                    <v-col cols="12" class="">Hieronder kunt u zien hoe de planning van de dag eruit ziet.</v-col>
                </v-row>
                <EventSlotCalendar v-if="roadtrip.roadtripState != RoadtripStateEnum.PASSED" :events="events" :eventDate="eventDate" />
                <v-row no-gutters>
                    <v-col>
                        <span class="display-1">{{roadtrip.impressionTitle}}</span>
                        <div class="mb-2">{{roadtrip.impressionText}}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="py-4">
                        <v-btn color="primary" block @click.stop="goto(roadtrip.galleryButtonURL)">
                            {{ roadtrip.galleryButtonText }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-footer v-if="dataLoaded"
            v-bind="localAttrs"
            :padless="true"
            class="aanmelden-footer" >
            <div class="bottom-section ma-2" v-if="showCustomButton">
                <v-container class="first-container pb-1">
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block @click="redirectCustomLink()">
                                {{ roadtrip.customButton }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div class="bottom-section ma-2" v-if="showTripDayStatisticsButton">
                <v-container class="first-container">
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block @click="goToTripStatistics(roadtrip.roadtripId)">
                                Bekijk de statistieken
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div class="bottom-section ma-2" v-if="showAanmeldenButton">
                <v-container class="first-container">
                    <v-row>
                        <v-col class="pt-0">
                            <v-btn color="primary" block  
                                v-if="isUserRegisteredToTrip"
                                @click="goToUpComingTripWijzigen(uniqueName)">
                                Update uw order
                            </v-btn>
                            <v-btn color="primary" block  
                                v-if="!isUserRegisteredToTrip"
                                @click="goToRoadtripAanmelden(roadtrip.tripName, formatDate(roadtrip.date))">
                                Aanmelden
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div class="bottom-section ma-2" v-if="inschrijvenNietMeerMogelijk">
                <v-container class="first-container">
                    <v-row>
                        <v-col>
                            <v-btn color="primary darken-3" block>
                                Inschrijven is niet (meer) mogelijk!
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-footer>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { RoadtripStateEnum } from '@/enums/RoadtripStateEnum';

import LoadingIcon from '@/components/common/LoadingIcon';
import Pakketten from '@/components/roadtrip/Pakketten';
import NoRoadtrip from '@/components/kalender/roadtrip-not-found';
import EventSlotCalendar from '@/components/common/EventSlotCalendar';
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";
import ImageDiv from '@/components/common/ImageDiv';

export default {
    name: 'RoadtripStore',
    components: 
    {
        ImageDiv,
        Pakketten,
        LoadingIcon,
        NoRoadtrip,
        EventSlotCalendar
    },
    mixins: [DataMixin, FormatMixin, RouteMixin, RoadtripMixin, MenuHelperMixin, MetaTagsMixin],
    data: () => ({
        isLoading: false,
        dataLoaded: false,
        roadtrip: {
            productPhotos: []
        },
        events: [],
        eventDate: null,
        isUserRegisteredToTrip: false,
        uniqueName: '',
        RoadtripStateEnum: RoadtripStateEnum,
    }),
    metaInfo() {
        return {
        title: this.roadtrip.tripName ? `| ${this.roadtrip.tripName}` : `| Kalender`,
        meta: [
            this.getKeywords(`${this.roadtrip.label}, ${this.roadtrip.tripName}`),
            this.getMetaDescription(`${this.roadtrip.productText}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.roadtrip.tripName}`, `${this.roadtrip.productText}`, this.metaPhoto, this.roadtrip.label),
        ]
        };
    },
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.uniqueName = this.$route.params.trip;
                this.getProduct();
            }
        }
    },
    computed: {
        ...mapState('authorizedModule', ['isAdmin', 'userId']),
        ...mapState('account', ['status', 'user']),
        showAanmeldenButton()
        {
            return this.roadtrip.displaySignupButton && !this.isLoading && !this.isAdmin;
        },
        showTripDayStatisticsButton()
        {
            return !this.isLoading && this.isAdmin;
        },
        showCustomButton()
        {
            return this.roadtrip.customButton && this.roadtrip.customButtonLink && !this.showTripDayStatisticsButton;
        },
        localAttrs () {
            const attrs = {
                fixed: true
            }
            
            return attrs;
        },
        metaPhoto() {
            if (this.roadtrip.productPhotos.length > 0)
            {
                return this.roadtrip.productPhotos[0].photoURL;
            }

            return '';
        },
        inschrijvenNietMeerMogelijk()
        {
            return !this.showAanmeldenButton && !this.showTripDayStatisticsButton;
        }
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', false);
    },
    methods: {
        redirectCustomLink()
        {
            window.location = this.roadtrip.customButtonLink;
        },
        getProduct()
        {
            if (this.userId != '')
            {
                this. getProductWhenLoggedIn();
            }
            else
            {
                this. getProductWhenLoggedOut();
            }
        },
        getProductWhenLoggedOut()
        {
            this.isLoading = true;
            this.$store.dispatch('productModule/getPublicProductByName', this.uniqueName)
                .then(data => {
                    this.mapRoadtrip(data);
                    this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        getProductWhenLoggedIn()
        {
            this.isLoading = true;
            this.$store.dispatch('productModule/getPublicProductByNameWhenLoggedIn', 
                {
                    uniqueName: this.uniqueName,
                    accountId: this.userId
                })
                .then(data => {
                    this.mapRoadtrip(data);
                    this.isUserRegisteredToTrip = data.requestingAccountAlreadySignedUp;
                    this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
        mapRoadtrip(data)
        {
            this.dataLoaded = data != "";
            if(!this.dataLoaded) return;

            this.events = [];
            this.roadtrip = this.mapRoadtripData(data);

            data.participantsItinerary.forEach(event => {
                this.events.push({
                    name: event.title,
                    start: `${this.getDateOnly(event.arrivalTime)} ${this.getTimeOnly(event.arrivalTime)}`,
                    end: `${this.getDateOnly(event.departureTime)} ${this.getTimeOnly(event.departureTime)}`,
                    link: event.googleMapsURL
                })
            });

            this.eventDate = this.events.length > 0 ? this.getDateOnly(this.events[0].start) : null;
            
        }
    }
}
</script>
<style>
.v-carousel .theme--dark.v-sheet
{
    background-color: #181717;
}

.roadtrip-store-container.with-botom-link
{
    padding-bottom: 60px;
    overflow-y: auto;
}


.roadtrip-store-container.with-botom-link.with-custom-button
{
    padding-bottom: 130px;
}

.aanmelden-footer.v-footer
{
    background: #181717 !important;
}
</style>